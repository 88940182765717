/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'

import { createRouter, createWebHistory } from 'vue-router'

import Home from './components/Home.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/:group/:category', component: Home, title: 'Ahihi' },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router

const app = createApp(App)

app.use(router)
app.mount('#layout-wrapper')
