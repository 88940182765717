<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">{{ group.replaceAll('_', ' ') }}</h4>
                            <p class="card-subtitle mb-4">
                                <code v-if="categoryIsString">{{ category }}</code>
                            </p>

                            <div class="row icons-list">
                                <div class="col-sm-6 col-lg-3 emoji-card"
                                    v-for="(e, idx) in list" :key="idx"
                                    @click="copyEmoji(e.value, idx)"
                                    title="Click to Copy"
                                >
                                    <div class="emoji" :id="`emoji_${idx}`">{{ e.value }}</div>
                                    <div class="emoji-name">{{ e.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'Home',
        props: {
            emoji: {},
            categories: {},
        },
        data() {
            return {
                list: [],
                group: 'Smileys & Emotion',
                category: 'all',
            }
        },
        computed: {
            categoryIsString() {
                return typeof this.category === 'string'
            },
        },
        methods: {
            getEmoji() {
                if (this.categoryIsString) {
                    this.list = this.emoji.filter(e => e.category == this.category)

                    if (this.list.length === 0) {
                        const group = this.categories.filter(g => g.name == this.group)[0]
                        if (group) {
                            this.category = group.sub
                        }
                        this.getEmoji()
                    }
                } else {
                    const cates = Object.keys(this.category)
                    this.list = this.emoji.filter(e => cates.includes(e.category))
                }

                document.title = `${this.group.replaceAll('_', ' & ')} - ${this.categoryIsString ? this.category : 'All'}`
            },
            copyEmoji(emoji, idx) {
                const storage = document.createElement('textarea');
                storage.value = document.getElementById(`emoji_${idx}`).innerHTML;

                // Select the text field
                storage.select();
                storage.setSelectionRange(0, 99999); // For mobile devices

                // Copy the text inside the text field
                navigator.clipboard.writeText(storage.value);

                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: `Emoji {${emoji}} belong to you!`,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
        },
        mounted() {
            const params = this.$route.params
            this.group = params.group || 'Smileys_Emotion'

            if (params.category && params.category != 'all') {
                this.category = params.category
            }

            if (this.category == 'all') {
                const group = this.categories.filter(g => g.name == this.group)[0]
                if (group) {
                    this.category = group.sub
                }
            }

            this.getEmoji()
        },
    }
</script>
