<template>
  <div>
    <div class="vertical-menu">
      <div data-simplebar class="h-100">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo">
            <span>
              <img src="/assets/images/logo-light.png" alt="All Emoji" height="15" width="100">
            </span>
            <i>
              <img src="/assets/images/logo-small.png" alt="All Emoji" height="42" width="41">
            </i>
          </router-link>
        </div>
        <div id="sidebar-menu">
          <!-- Left Menu Start -->
          <ul class="metismenu list-unstyled" id="side-menu">
            <li class="menu-title">Categories</li>

            <li>
              <router-link to="/">
                <span class="badge badge-pill badge-success float-right">{{ emoji.length }}</span>
                <span>All Emojis</span>
              </router-link>
            </li>

            <li v-for="(c, idx) in categories" :key="idx">
              <a href="#" class="has-arrow">
                {{ c.emoji }} <span>{{ c.name.replaceAll('_', ' & ') }}</span>
              </a>
              <ul class="sub-menu">
                <li>
                  <router-link :to="`/${c.name}/all`">{{ c.emoji }} All</router-link>
                </li>
                <li v-for="(s, subName) in c.sub" :key="subName" @click="category = subName; group = `${c.emoji} ${c.name}`">
                  <router-link :to="`/${c.name}/${subName}`">{{ s }} {{ subName }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="main-content">
      <header id="page-topbar">
        <div class="navbar-header">
          <div class="d-flex align-items-center">
            <button type="button" class="btn btn-sm mr-2 d-lg-none header-item" id="vertical-menu-btn">
              <i class="fa fa-fw fa-bars"></i>
            </button>

            <div class="header-breadcumb">
              <h6 class="header-pretitle d-none d-md-block">Emoji <i class="dripicons-arrow-thin-right"></i> {{ group }}
              </h6>
              <h2 class="header-title">
                <span v-if="(typeof category == 'string')">
                  {{ category }}
                </span>
                <span v-else>All</span>
              </h2>
            </div>
          </div>
        </div>
      </header>
      <div class="page-content">
        <router-view :emoji="emoji" :key="$route.fullPath" :categories="categories" />
      </div>
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              2019 © Emoji.
            </div>
            <div class="col-sm-6">
              <div class="text-sm-right d-none d-sm-block">
                Design & Develop by David Hoang
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  /* App css */
  import '../src/assets/css/bootstrap.min.css'
  import '../src/assets/css/icons.min.css'
  import '../src/assets/css/sweetalert2.min.css'
  import '../src/assets/css/theme.css'

  /* eslint-disable */
  import categories from '../src/data/categories'
  import emoji from '../src/data/emojiall'

  export default {
    name: 'App',
    components: {
      categories,
      emoji,
    },
    data() {
      return {
        category: 'face-tongue',
        group: '😂 Smileys & Emotion',
        categories: [],
        emoji: {},
      }
    },
    methods: {
      
    },
    mounted() {
      this.categories = categories
      this.emoji = emoji
      this.category = this.categories[0].sub
    },
  }
</script>
